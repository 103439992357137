import {constructor} from '../../../../mixins/constructor';

export default {
    name: "industrial",
    mixins: [constructor],
    data() {
        return {
            complexId: '',
            hoverdBuilding: '',
            selectedBuilding: '',
            hoveredBuildingElement: '',
            selectedBuildingElement: '',
            categoryList: '',
            coords: [
                '34,340,233,457,370,378,327,355,327,327,352,312,410,347,475,305,447,291,442,248,400,227,366,249,353,244,353,188,344,180,347,150,336,148,334,170,322,163,322,139,315,136,312,158,303,153,302,126,296,125,292,148,274,138,243,152,207,177,191,180,170,203,163,204,158,212,115,231,68,259,64,322',
                '445,354,483,376,531,348,498,325,475,332,454,346,454,352',
                '494,201,520,217,506,229,601,286,618,277,645,287,857,161,815,137,815,85,823,80,718,15,714,4,704,2,693,7,688,21,677,28,671,44,584,90,579,50,570,52,563,104,531,124,528,181',
                '718,330,819,388,835,379,908,416,1037,341,999,320,995,233,927,193,924,152,914,149,905,153,821,209,819,232,774,260,753,280,747,315',
                '388,465,556,561,654,506,489,408,474,406,454,406,428,432,420,445',
                '462,561,510,587,550,569,540,565,544,555,516,544,491,540',
                '565,437,671,498,765,442,659,384,641,383,630,401,608,402,595,419',
                '622,553,699,596,825,524,799,506,798,497,750,469,731,475,669,514,670,527',
                '78,231,411,419,421,389,436,366,456,342,480,326,499,320,518,318,538,323,192,123,174,124,150,136,124,148,104,171',
                '413,421,422,390,435,368,455,345,480,327,498,318,518,318,536,324,549,343',
                '472,170,644,268,678,222,722,225,758,174,796,176,623,81,588,80,552,123,507,127',
                '647,266,679,222,719,224,758,175,797,176,797,216,648,304',
                '494,191,620,261,621,267,494,199',
                '208,471,224,473,243,475,313,505,298,534,208,486',
                '207,471,250,447,268,445,284,445,302,451,357,480,312,504,258,476',
                '609,438,657,465,709,421,663,395',
                '679,374,679,353,702,351,703,342,723,338,726,331,744,326,746,316,767,310,767,307,787,300,788,293,814,289,856,311,907,350,782,426',
                '714,425,761,451,829,416,826,424,763,460,713,434',
                '653,380,761,443,943,340,941,349,763,451,653,391',
                '711,434,759,461,827,425,829,433,781,460,781,500,762,511,711,483',
                '939,364,944,361,946,406,938,409',
                '653,380,761,443,943,340,941,349,763,451,653,391',
                '711,434,759,461,827,425,829,433,781,460,781,500,762,511,711,483',
                '939,364,944,361,946,406,938,409',
                '115,235,249,311,249,439,114,365',
                '131,234,192,189,197,185,273,154,407,234,333,262,326,268,258,322,254,308',
                '269,341,407,262,416,269,278,348',
                '302,371,429,300,472,315,530,358,407,431',
                '266,374,406,452,554,369,554,384,407,468,264,390',
                '287,142,297,126,313,112,332,101,355,102,486,179,458,178,442,188,423,209,414,226,412,216',
                '356,91,407,55,415,50,462,31,596,109,547,128,542,132,490,168',
                '489,178,601,117,602,199,488,263',
                '626,221,637,212,638,199,661,199,662,189,685,188,746,222,687,256',
                '618,239,655,257,656,293,689,311,744,280,746,244,755,240,756,308,689,346,618,308',
                '773,378,814,358,827,355,838,353,848,352,882,373,859,373,806,398',
                '806,398,860,374,883,374,885,382,821,420',
                '78,231,411,419,421,389,436,366,456,342,480,326,499,320,518,318,538,323,192,123,174,124,150,136,124,148,104,171',
                '413,421,422,390,435,368,455,345,480,327,498,318,518,318,536,324,549,343',
                '354,252,419,288,420,239,554,316,678,243,684,316,889,200,895,298,683,416,682,432,601,478,599,433,553,457,420,380,422,345,388,331,382,356,352,343',
                '460,288,502,310,504,250,596,300,621,286,602,272,602,240,739,318,890,235,891,415,741,501,626,438,597,452,459,374',
                '1078,614,1080,616'

            ],

            radioArr: [],
            coordsArr: []
        }
    },
    watch: {},
    created() {
    },
    mounted() {
    },
    computed: {},
    methods: {}
}
